import { color } from "echarts";
import React, { useState } from "react";

type Params = {
    width?: number;
    progress?: number;
    className?: string;
    colorLine?: string;
    colorText?: string;
    ringType?: string;
    visible?: boolean;
    typeOfYear?: string;
    numberTypeOfYear?: number;
    available?: boolean;
};

function ProgressCircle({
    width = 15,
    progress = 0.65,
    className = "",
    colorLine,
    colorText,
    ringType,
    visible,
    typeOfYear,
    numberTypeOfYear,
    available,
}: Params) {
    const radius = 100;

    const itog = Number(progress.toPrecision(1));

    const [visibleText, setVisibleText] = useState(false);

    return (
        <div className="relative">
            <p
                className={`absolute text-[${colorText}] 
                ${
                    ringType === "big" &&
                    "flex h-full w-full items-center justify-center text-tr-xl font-bold"
                } 
                ${
                    ringType === "middle" &&
                    "flex h-full w-full items-center justify-center text-tr-l font-bold"
                }
                ${
                    ringType === "short" &&
                    "flex h-full w-full items-center justify-center text-tr-m font-bold"
                }
                `}
            >
                {available === false ? (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 10V8C6 7.65929 6.0284 7.32521 6.08296 7M18 10V8C18 4.68629 15.3137 2 12 2C10.208 2 8.59942 2.78563 7.5 4.03126"
                            stroke="#F37D73"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                        <path
                            d="M11 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15"
                            stroke="#F37D73"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                    </svg>
                ) : (
                    <>{itog * 100} % </>
                )}
            </p>
            <svg
                onMouseEnter={() => setVisibleText(true)}
                onMouseLeave={() => setVisibleText(false)}
                className={`absolute right-0 top-0 ${
                    visible ? " " : " invisible"
                }`}
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="1" y="1" width="24" height="24" rx="12" fill="white" />
                <rect
                    x="1"
                    y="1"
                    width="24"
                    height="24"
                    rx="12"
                    stroke="#F37D73"
                    stroke-width="2"
                />
                <path
                    d="M12.1949 14.406C12.0922 14.406 12.0082 14.3733 11.9429 14.308C11.8776 14.2333 11.8449 14.1493 11.8449 14.056V8.05C11.8449 7.94733 11.8776 7.86333 11.9429 7.798C12.0082 7.73267 12.0922 7.7 12.1949 7.7H13.8049C13.9076 7.7 13.9916 7.73267 14.0569 7.798C14.1222 7.86333 14.1549 7.94733 14.1549 8.05V14.056C14.1549 14.1493 14.1222 14.2333 14.0569 14.308C13.9916 14.3733 13.9076 14.406 13.8049 14.406H12.1949ZM12.1389 17.5C12.0362 17.5 11.9522 17.4673 11.8869 17.402C11.8216 17.3273 11.7889 17.2433 11.7889 17.15V15.428C11.7889 15.3253 11.8216 15.2413 11.8869 15.176C11.9522 15.1013 12.0362 15.064 12.1389 15.064H13.8609C13.9542 15.064 14.0336 15.1013 14.0989 15.176C14.1736 15.2413 14.2109 15.3253 14.2109 15.428V17.15C14.2109 17.2433 14.1736 17.3273 14.0989 17.402C14.0336 17.4673 13.9542 17.5 13.8609 17.5H12.1389Z"
                    fill="#F37D73"
                />
            </svg>
            <div
                className={`absolute ${
                    visibleText ? " " : " invisible"
                }  left-[55px] top-[-60px] flex h-[50px] w-[240px] items-center justify-center rounded-md bg-white p-[10px] shadow-[0_0_15px_0_rgba(115,189,233,0.2)]`}
            >
                <p className="text-tr-xs font-normal text-main-dark">
                    Этот навык нужно подтвердить через
                    <p className="text-tr-xs font-bold text-main-dark">
                        {numberTypeOfYear} {typeOfYear}
                    </p>
                </p>
            </div>
            <svg
                className={className}
                viewBox={`0 0 ${radius * 2.2} ${radius * 2.2}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    className="text-slightblue opacity-20"
                    strokeWidth={width}
                    stroke={colorLine}
                    fill="transparent"
                    r={radius}
                    cx="50%"
                    cy="50%"
                />
                <circle
                    className="text-blue"
                    strokeWidth={width}
                    strokeDasharray={2 * Math.PI * radius}
                    strokeDashoffset={
                        2 * Math.PI * radius - 2 * Math.PI * radius * progress
                    }
                    strokeLinecap="round"
                    stroke={colorLine}
                    fill="transparent"
                    transform={`rotate(-90 ${radius * 1.1} ${radius * 1.1})`}
                    r={radius}
                    cx="50%"
                    cy="50%"
                />
            </svg>
        </div>
    );
}

export default ProgressCircle;
