import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import { useStore } from "effector-react";
import * as types from "shared/types";

import {
    ActiveThreatType,
    TrajectoryActiveType,
    MaterialType,
} from "shared/types";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import { ActiveCard } from "./ActiveCard";
import { ThreatCard } from "./ThreatCard";
import { useQuery } from "@tanstack/react-query";

const TrajectoryActivesModel =
    CreatePaginatedModel<types.TrajectoryActiveType>("TrajectoryActives");
const ActiveThreatsModel =
    CreatePaginatedModel<types.ActiveThreatType>("ActiveThreats");
const SkillsContentModel =
    CreatePaginatedModel<types.MaterialType>("SkillsContent");

export const Timeline: React.FC = () => {
    const [isLoadingActive, setIsLoadingActive] = useState(true);
    const [isLoadingThreats, setIsLoadingThreats] = useState(true);
    const [isLoadingContent, setIsLoadingContent] = useState(false);

    const [openThreats, setOpenThreats] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);

    const location = useLocation();
    const goal = location.state.leftActive;

    const actives = useStore(TrajectoryActivesModel.store); // Запрос модели цели профиля
    const activesList = actives.results; // Получение результатов модели
    const threat = useStore(ActiveThreatsModel.store); // Запрос модели навыков профиля
    const threatList = threat.results; // Получение результатов модели
    const content = useStore(SkillsContentModel.store); // Получение модели умов навыка
    const contentList = content.results; // Получение результатов модели

    const activeGoalList = activesList?.filter(
        (item) => item.content_count > 0
    );
    const activeListLenght = activeGoalList.length;

    const activeSkillList = threatList.filter((item) => item.content_count > 0);

    const [editActive, setEditActive] = useState<TrajectoryActiveType>(goal);
    const [editThreat, setEditThreat] = useState<ActiveThreatType>();
    const [editMaterial, setEditMaterial] = useState<MaterialType>();
    const [openedThreat, setOpenedThreat] = useState("");

    useQuery({
        queryKey: ["actives-list"],
        queryFn: () => {
            ModelApi1.loadFirstPage();
        },
    });

    useQuery({
        queryKey: ["skills-list"],
        queryFn: () => {
            ModelApi2.loadFirstPage();
        },
    });

    const ModelApi1 = CreateModelApi(
        TrajectoryActivesModel,
        `/api/v1/user/trajectory_actives`,
        "",
        150
    );

    const ModelApi2 = CreateModelApi(
        ActiveThreatsModel,
        `/api/v1/user/trajectory_active/${editActive?.id}/threats`,
        "",
        150
    );

    const ModelApi3 = CreateModelApi(
        SkillsContentModel,
        `/api/v1/user/active_threat/${editThreat?.id}/content`,
        "",
        150
    );

    const threatToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    for (const [i, active_threat] of threatList.entries()) {
        if (i === 0) {
            threatToCompletion[active_threat.id] = {
                isAvailable: true,
                prev: null,
            };
        } else {
            const prevThreat = threatList[i - 1];
            const nextElem = { isAvailable: false, prev: prevThreat.id };
            const prevAvailable = threatToCompletion[prevThreat.id].isAvailable;
            const prevThreatCompletion =
                prevThreat.content_count - prevThreat.completed_content_count;
            if (prevAvailable && prevThreatCompletion === 0) {
                nextElem.isAvailable = true;
            }

            threatToCompletion[active_threat.id] = nextElem;
        }
    }

    // Старое
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);

    const handleClickLinkGoal = (evt) => {
        evt.stopPropagation();
        navigate("/goals");
    };

    const handleClickLinkMain = (evt) => {
        evt.stopPropagation();
        navigate("/");
    };

    const handleClickLinkBack = (evt) => {
        evt.stopPropagation();
        navigate(-1);
    };

    const scrollLeft = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(-250, 0);
    };

    const scrollRight = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(250, 0);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
        if (isLoadingActive) {
            ModelApi1.loadFirstPage().then(() => {
                setIsLoadingActive(false);
            });
        }
        if (isLoadingThreats) {
            ModelApi2.loadFirstPage().then(() => {
                setIsLoadingThreats(false);
            });
        }
        if (isLoadingContent) {
            ModelApi3.loadFirstPage().then(() => {
                setIsLoadingContent(false);
            });
        }
    }, [width, isLoadingActive, isLoadingThreats, isLoadingContent]);

    return (
        <main className="flex w-full flex-col overflow-auto">
            <section className="relative flex w-full justify-center">
                <div className="mb-[10px] mt-[30px] flex w-full max-w-[1415px] justify-between max-[834px]:mb-[60px]">
                    <div className="flex h-screen w-full flex-col">
                        <div className="mb-[45px] flex flex-col gap-[19px] max-[834px]:mb-[40px]">
                            <p className="cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                                <span onClick={handleClickLinkBack}>
                                    &#8592;
                                </span>{" "}
                                <span onClick={handleClickLinkMain}>
                                    Главная
                                </span>{" "}
                                <span onClick={handleClickLinkGoal}>/Цели</span>
                            </p>
                            <h2 className="text-[20px] font-bold leading-[24px] text-[#264354]">
                                {goal?.active?.name}
                            </h2>
                        </div>
                        {isLoadingActive === false &&
                        isLoadingThreats === false ? (
                            <div className="flex w-full gap-x-[30px]">
                                <div className="flex flex-col gap-y-[40px]">
                                    <p className="text-tr-l font-medium text-main-dark">
                                        Все цели
                                    </p>
                                    <div className="flex flex-col">
                                        {activeGoalList !== undefined && (
                                            <>
                                                {activeGoalList?.map(
                                                    (active, index) => {
                                                        return (
                                                            <ActiveCard
                                                                active={active}
                                                                setEditActive={
                                                                    setEditActive
                                                                }
                                                                setIsLoadingThreats={
                                                                    setIsLoadingThreats
                                                                }
                                                                setOpenMaterials={
                                                                    setOpenMaterials
                                                                }
                                                                setOpenThreats={
                                                                    setOpenThreats
                                                                }
                                                                activeSelected={
                                                                    editActive
                                                                }
                                                                index={index}
                                                                activeListLenght={
                                                                    activeListLenght
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex w-full flex-col gap-y-[40px]">
                                    <div className="flex flex-col gap-y-[10px]">
                                        <p className="flex gap-x-[5px] text-tr-l font-medium text-[#369F48]">
                                            Навыки в цели
                                        </p>
                                        <p className="text-tr-l font-medium text-main-dark">
                                            {editActive.active.name}
                                        </p>
                                    </div>

                                    <div className="mb-[20px] flex w-full flex-col gap-y-[20px]">
                                        {activeSkillList !== undefined && (
                                            <>
                                                {activeSkillList?.map(
                                                    (skill, index) => {
                                                        return (
                                                            <ThreatCard
                                                                threat={skill}
                                                                setEditMaterial={
                                                                    setEditMaterial
                                                                }
                                                                setEditThreat={
                                                                    setEditThreat
                                                                }
                                                                setOpenedThreat={
                                                                    setOpenedThreat
                                                                }
                                                                editThreat={
                                                                    editThreat
                                                                }
                                                                openedThreat={
                                                                    openedThreat
                                                                }
                                                                index={index}
                                                                activeOrdered={
                                                                    editActive.is_ordered
                                                                }
                                                                threatListLength={
                                                                    activeSkillList.length
                                                                }
                                                                available={
                                                                    threatToCompletion[
                                                                        skill.id
                                                                    ]
                                                                        .isAvailable
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex h-full w-full">
                                <Loader text="Загружаю" />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Timeline;
