import { truncateText } from "utils/truncateText";
import { useEffect } from "react";
import { useState } from "react";
import { useStore } from "effector-react";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import * as types from "shared/types";
import { LoaderConstructor } from "./LoaderConstructor/LoaderConstructor";
import { MaterialCard } from "./MaterialCard";
import ProgressCircle from "shared/ui/ProgressCircle";
import { useSessionPopup } from "components/MaterialSessionPopup/model";
import { ReactComponent as TestArrow } from "../../shared/ui/icon/TestArrow.svg";
import { ArrowComponent } from "./ArrowComponent/ArrowComponent";
import { useQuery } from "@tanstack/react-query";

interface Props {
    threat: types.ActiveThreatType | undefined;
    setEditThreat: React.Dispatch<
        React.SetStateAction<types.ActiveThreatType | undefined>
    >;
    editThreat: types.ActiveThreatType | undefined;
    setOpenedThreat: React.Dispatch<React.SetStateAction<string>>;
    openedThreat: string;
    setEditMaterial: React.Dispatch<
        React.SetStateAction<types.MaterialType | undefined>
    >;
    activeOrdered: boolean;
    index: number;
    threatListLength: number;
    available: boolean;
}

const SkillsContentModel =
    CreatePaginatedModel<types.MaterialType>("SkillsContent");

export const ThreatCard: React.FC<Props> = ({
    threat,
    setEditThreat,
    editThreat,
    setOpenedThreat,
    openedThreat,
    setEditMaterial,
    activeOrdered,
    index,
    threatListLength,
    available,
}) => {
    const content = useStore(SkillsContentModel.store); // Получение модели умов навыка
    const contentList = content.results; // Получение результатов модели
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);
    const visible = threat.reset_time !== null;

    if (activeOrdered === false) {
        available = true;
    }

    const materialToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    for (const [i, material] of contentList.entries()) {
        if (i === 0) {
            materialToCompletion[material.id] = {
                isAvailable: true,
                prev: null,
            };
        } else {
            const prevMat = contentList[i - 1];
            const nextElem = { isAvailable: false, prev: prevMat.id };
            const prevAvailable = materialToCompletion[prevMat.id].isAvailable;

            if (prevAvailable && prevMat.session.percent_progress >= 100) {
                nextElem.isAvailable = true;
            }

            materialToCompletion[material.id] = nextElem;
        }
    }

    function openThreat() {
        if (available === true) {
            if (openMaterials === false) {
                setEditThreat(threat);
                setOpenMaterials(true);
                setIsLoadingContent(true);
                if (threat !== undefined) setOpenedThreat(threat.threat.name);
            } else {
                setEditThreat(threat);
                setOpenMaterials(false);
                setIsLoadingContent(false);
            }
        }
    }

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number) => {
        if (available === true) {
            if (typeof threatId !== "number") {
                throw new Error("invalid threatId");
            }
            openActiveMaterial(null, threatId, null);
        }
    };

    function closeThreat() {
        setEditThreat(threat);
        setOpenMaterials(false);
        setIsLoadingContent(false);
    }

    useQuery({
        queryKey: ["materials-list"],
        queryFn: () => {
            if (editThreat !== undefined) {
                ModelApi3.loadFirstPage();
            }
        },
    });

    const [typeOfYear, setTypeOfYear] = useState("");
    const [numberTypeOfYear, setNumberTypeOfYear] = useState(0);

    const fullResetTime = threat?.reset_time;
    const timer = " 00:00:00";
    const days = Number(fullResetTime?.replace(timer, ""));

    const ModelApi3 = CreateModelApi(
        SkillsContentModel,
        `/api/v1/user/active_threat/${editThreat?.id}/content`,
        "",
        150
    );

    console.log(threat);

    useEffect(() => {
        if (isLoadingContent) {
            ModelApi3.loadFirstPage().then(() => {
                setIsLoadingContent(false);
            });
        }
        if (openedThreat !== threat.threat.name) {
            setOpenMaterials(false);
            console.log(openedThreat);
        }
        if (Number.isInteger(days / 365) === true) {
            setTypeOfYear("год(лет)");
            setNumberTypeOfYear(days / 365);
        } else if (Number.isInteger(days / 30) === true) {
            setTypeOfYear("месяц(а)");
            setNumberTypeOfYear(days / 30);
        } else if (Number.isInteger(days / 7) === true) {
            setTypeOfYear("неделя(ь)");
            setNumberTypeOfYear(days / 7);
        } else if (Number.isInteger(days / 1) === true) {
            setTypeOfYear("день(дней)");
            setNumberTypeOfYear(days);
        }
    }, [threat, openMaterials, contentList, openedThreat]);

    return (
        <div
            className="realtive flex min-h-[170px] w-full max-w-[930px] cursor-pointer rounded border-[1px] border-white p-[20px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)] hover:border hover:border-[#369F48]"
            onClick={openThreat}
        >
            <div className="flex flex-col items-center ">
                <ProgressCircle
                    className="h-[80px] w-[80px] "
                    colorLine="#369F48"
                    colorText="#369F48"
                    ringType="short"
                    progress={
                        threat?.completed_content_count / threat?.content_count
                    }
                    visible={visible}
                    typeOfYear={typeOfYear}
                    numberTypeOfYear={numberTypeOfYear}
                />
                {/* <div className="relative flex h-full border-dashed border-[1px] border-main-dark ">
                    <div className="absolute text-main-dark text-[20px] font-black bottom-[-11px] right-[-6px]">
                        &#709;
                    </div>
                </div> */}

                {/* <div className="relative flex h-full  ">
                    <TestArrow className="w-[25px]"/>
                </div> */}
                <ArrowComponent />
            </div>
            <div className="ml-[20px] flex w-full flex-col justify-between">
                <div className="flex w-full justify-between pt-[30px]">
                    <p
                        className={`text-[16px] font-medium leading-[19.2px] ${
                            available ? " " : "text-gray-500 opacity-60"
                        }`}
                    >
                        {truncateText(threat?.threat?.name, 2)}
                    </p>
                    {/* <div className="flex flex-col gap-y-[10px]">
                        <p className="text-tr-s text-main-dark font-medium">Время прохождения</p>
                        <div className="flex items-center justify-center h-[25px] w-full max-w-[145px] bg-[#F37D731A] text-tr-s font-normal text-[#F37D73] rounded">
                            2 часа 15 минут
                        </div>
                    </div> */}
                </div>
                {openMaterials === false ? (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="flex w-full justify-between"
                    >
                        <div
                            className={` ${
                                available ? " " : "text-gray-500 opacity-60"
                            } flex items-end gap-x-[10px]`}
                            onClick={openThreat}
                        >
                            <p className="text-[14px] font-medium leading-[16.8px] text-[#264354] underline">
                                Чтобы освоить навык, необходимо изучить
                            </p>
                            <div className="flex h-[25px] min-w-[80px] items-center justify-center gap-x-[10px] rounded bg-[#26435433] px-[20px]">
                                <p className="text-[12px] font-normal leading-[14.4px] text-[#264354]">
                                    {threat.content_count || 0} материалов
                                </p>
                                &#8595;
                            </div>
                        </div>
                        <div
                            onClick={() => handleClickLinkThreat(threat.id)}
                            className={`flex h-[35px] w-full max-w-[185px] items-center justify-center gap-x-[10px] rounded-full ${
                                available
                                    ? "text-[#566DA3] hover:bg-[#566DA3] hover:text-white"
                                    : "text-gray-500 opacity-60"
                            } shadow-[0_0_10px_0_rgba(38,67,84,0.1)] `}
                        >
                            <p className="text-tr-s font-medium">
                                Изучить сразу
                            </p>
                            &#10230;
                        </div>
                    </div>
                ) : (
                    <>
                        {!isLoadingContent ? (
                            <>
                                {contentList.length > 0 ? (
                                    <div className="relative mt-[45px] flex flex-col">
                                        <div className="flex max-w-[775px] items-end justify-between">
                                            <div className="flex items-end gap-x-[10px]">
                                                <p className="mt-[19px] text-[14px] font-medium leading-[16.8px] text-[#264354] underline">
                                                    Чтобы освоить навык,
                                                    необходимо изучить
                                                </p>
                                                <div className="flex h-[25px] min-w-[80px] items-center justify-center gap-x-[10px] rounded bg-[#26435433] px-[20px]">
                                                    <p className="text-[12px] font-normal leading-[14.4px] text-[#264354]">
                                                        {threat.content_count ||
                                                            0}{" "}
                                                        материалов
                                                    </p>
                                                    &#8593;
                                                </div>
                                            </div>
                                            <div
                                                onClick={() =>
                                                    handleClickLinkThreat(
                                                        threat.id
                                                    )
                                                }
                                                className="mr-[-15px] flex h-[35px] w-full max-w-[185px] items-center justify-center gap-x-[10px] rounded-full text-[#566DA3] shadow-[0_0_10px_0_rgba(38,67,84,0.1)] hover:bg-[#566DA3] hover:text-white"
                                            >
                                                <p className="text-tr-s font-medium">
                                                    Изучить сразу
                                                </p>
                                                &#10230;
                                            </div>
                                        </div>
                                        <div className="relative mt-[30px] flex flex-col gap-[20px]">
                                            {contentList.map(
                                                (material, index) => (
                                                    <MaterialCard
                                                        material={material}
                                                        setEditMaterial={
                                                            setEditMaterial
                                                        }
                                                        threatId={threat.id}
                                                        contentList={
                                                            contentList
                                                        }
                                                        index={index}
                                                        threatOrdered={
                                                            threat.is_ordered
                                                        }
                                                        available={
                                                            materialToCompletion[
                                                                material.id
                                                            ].isAvailable
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex h-full w-full flex-col justify-center">
                                        <div className="flex flex-col items-center gap-y-[20px]">
                                            <p className="mb-[20px] text-center text-[16px] font-semibold leading-[19.2px] text-[#F37D73]">
                                                Упс, кажется тут пусто. <br />
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex h-[65px] w-full items-center justify-center">
                                <LoaderConstructor />
                            </div> // Лоадер
                        )}
                    </>
                )}
            </div>
            {activeOrdered === true && (
                <div className="ml-[10px] flex flex-col items-center gap-y-[10px]">
                    <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.2646 2.37603C12.4498 1.18617 14.2754 1.08972 15.3421 2.16058C16.4089 3.23145 16.3128 5.06411 15.1275 6.25396L13.4106 7.97751M7.6162 9.9165C6.54948 8.84564 6.64556 7.01296 7.83081 5.8231L9.35417 4.29384"
                            stroke="#F37D73"
                            stroke-width="1.0625"
                            stroke-linecap="round"
                        />
                        <path
                            d="M10.3831 7.0835C11.4499 8.15437 11.3538 9.98701 10.1685 11.1769L8.45167 12.9004L6.7348 14.624C5.54954 15.8138 3.72395 15.9103 2.65722 14.8394C1.59049 13.7685 1.68658 11.9359 2.87183 10.746L4.58872 9.02245"
                            stroke="#F37D73"
                            stroke-width="1.0625"
                            stroke-linecap="round"
                        />
                    </svg>
                    {index + 1 !== threatListLength &&
                    activeOrdered === true ? (
                        <svg
                            width="8"
                            height="102"
                            viewBox="0 0 8 102"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.64644 101.354C3.8417 101.549 4.15829 101.549 4.35355 101.354L7.53553 98.1716C7.73079 97.9763 7.73079 97.6597 7.53553 97.4645C7.34027 97.2692 7.02369 97.2692 6.82842 97.4645L4 100.293L1.17157 97.4645C0.976306 97.2692 0.659724 97.2692 0.464462 97.4645C0.2692 97.6597 0.2692 97.9763 0.464462 98.1716L3.64644 101.354ZM3.5 -2.18557e-08L3.5 2.525L4.5 2.525L4.5 2.18557e-08L3.5 -2.18557e-08ZM3.5 7.575L3.5 12.625L4.5 12.625L4.5 7.575L3.5 7.575ZM3.5 17.675L3.5 22.725L4.5 22.725L4.5 17.675L3.5 17.675ZM3.5 27.775L3.5 32.825L4.5 32.825L4.5 27.775L3.5 27.775ZM3.5 37.875L3.5 42.925L4.5 42.925L4.5 37.875L3.5 37.875ZM3.5 47.975L3.5 53.025L4.5 53.025L4.5 47.975L3.5 47.975ZM3.5 58.075L3.5 63.125L4.5 63.125L4.5 58.075L3.5 58.075ZM3.5 68.175L3.5 73.225L4.5 73.225L4.5 68.175L3.5 68.175ZM3.5 78.275L3.5 83.325L4.5 83.325L4.5 78.275L3.5 78.275ZM3.5 88.375L3.5 93.425L4.5 93.425L4.5 88.375L3.5 88.375ZM3.5 98.475L3.5 101L4.5 101L4.5 98.475L3.5 98.475Z"
                                fill="#F37D73"
                            />
                        </svg>
                    ) : (
                        <svg
                            width="6"
                            height="104"
                            viewBox="0 0 6 104"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3 98.3333C1.52724 98.3333 0.333329 99.5272 0.333329 101C0.333329 102.473 1.52724 103.667 3 103.667C4.47275 103.667 5.66666 102.473 5.66666 101C5.66666 99.5272 4.47276 98.3333 3 98.3333ZM2.5 -2.18557e-08L2.5 2.525L3.5 2.525L3.5 2.18557e-08L2.5 -2.18557e-08ZM2.5 7.575L2.5 12.625L3.5 12.625L3.5 7.575L2.5 7.575ZM2.5 17.675L2.5 22.725L3.5 22.725L3.5 17.675L2.5 17.675ZM2.5 27.775L2.5 32.825L3.5 32.825L3.5 27.775L2.5 27.775ZM2.5 37.875L2.5 42.925L3.5 42.925L3.5 37.875L2.5 37.875ZM2.5 47.975L2.5 53.025L3.5 53.025L3.5 47.975L2.5 47.975ZM2.5 58.075L2.5 63.125L3.5 63.125L3.5 58.075L2.5 58.075ZM2.5 68.175L2.5 73.225L3.5 73.225L3.5 68.175L2.5 68.175ZM2.5 78.275L2.5 83.325L3.5 83.325L3.5 78.275L2.5 78.275ZM2.5 88.375L2.5 93.425L3.5 93.425L3.5 88.375L2.5 88.375ZM2.5 98.475L2.5 101L3.5 101L3.5 98.475L2.5 98.475Z"
                                fill="#F37D73"
                            />
                        </svg>
                    )}
                </div>
            )}
        </div>
    );
};
