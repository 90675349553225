import { useQuery } from "@tanstack/react-query";
import ContentAdapter from "pages/session/ContentAdapter";
import * as http from "shared/http";
import {
    TrajectoryActiveType,
    ActiveThreatType,
    Content,
    Paginated,
} from "shared/types";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as ListClose } from "../../shared/ui/icon/ListClose.svg";
import { ReactComponent as ListOpen } from "../../shared/ui/icon/ListOpen.svg";
import { ReactComponent as SelectedMaterial } from "../../shared/ui/icon/SelectedMaterial.svg";
import { ReactComponent as FinishedMaterial } from "../../shared/ui/icon/FinishedMaterial.svg";
import { useSessionPopup } from "./model";
import { useEffect, useState } from "react";
import { PopupTest } from "components/PopupTest/PopupTest";
import { truncateText } from "../../utils/truncateText";
import { Loader } from "components/Loader/Loader";

function ListElem({
    m,
    materialId,
    setMaterialId,
    setOpenList,
    setSelectedMaterial,
    available,
    threatOrdered,
}: {
    m: Content;
    materialId: number;
    setMaterialId: React.Dispatch<React.SetStateAction<number>>;
    setOpenList: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedMaterial: React.Dispatch<React.SetStateAction<Content>>;
    available: boolean;
    threatOrdered: boolean;
}) {
    if (threatOrdered === false) {
        available = true;
    }

    function openMaterial() {
        if (available !== false) {
            setMaterialId(m.id);
            setOpenList(false);
            setSelectedMaterial(m);
        }
    }

    return (
        <div className="flex gap-x-3">
            <div className="relative after:absolute after:bottom-0 after:start-3.5 after:top-7 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 last:after:hidden">
                <div className="relative z-10 mt-[7px] flex size-7 items-center justify-center">
                    {materialId === m.id ? (
                        <SelectedMaterial />
                    ) : (
                        <FinishedMaterial
                            className={` ${
                                available === false
                                    ? " fill-[#BDD7E7]"
                                    : " fill-[#566DA3]"
                            }`}
                        />
                    )}
                </div>
            </div>
            <div className="grow pb-8 pt-0.5">
                <div
                    className="flex cursor-pointer items-end"
                    onClick={openMaterial}
                >
                    <p
                        className={`w-[40px]  text-tr-m font-medium ${
                            materialId === m.id
                                ? " text-orange"
                                : " text-main-dark"
                        }`}
                    >
                        {m.session.percent_progress}%
                    </p>
                    <div className="ml-5 flex flex-col">
                        <div className="flex items-center gap-x-[5px]">
                            {available === false && (
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_11119_184352)">
                                        <path
                                            d="M3 5V4C3 3.82964 3.0142 3.6626 3.04148 3.5M9 5V4C9 2.34315 7.65685 1 6 1C5.10398 1 4.29971 1.39282 3.75 2.01563"
                                            stroke="#F37D73"
                                            stroke-width="0.75"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M5.5 11H4C2.58579 11 1.87868 11 1.43934 10.5607C1 10.1213 1 9.41421 1 8C1 6.58579 1 5.87868 1.43934 5.43934C1.87868 5 2.58579 5 4 5H8C9.41421 5 10.1213 5 10.5607 5.43934C11 5.87868 11 6.58579 11 8C11 9.41421 11 10.1213 10.5607 10.5607C10.1213 11 9.41421 11 8 11H7.5"
                                            stroke="#F37D73"
                                            stroke-width="0.75"
                                            stroke-linecap="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_11119_184352">
                                            <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            )}
                            <p className="text-tr-xxs font-normal text-main-dark">
                                {m.content_type.custom_name}
                            </p>
                        </div>
                        <p className="text-tr-s font-medium text-main-dark">
                            {m.name}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getThreatContent(threatId: number) {
    return http.request<Paginated<Content>>({
        method: "get",
        url: `/api/v1/user/active_threat/${threatId}/content`,
    });
}

function getThreatDetails(threatId: number) {
    return http.request<ActiveThreatType>({
        method: "get",
        url: `/api/v1/active_threat/${threatId}`,
    });
}

function getActiveContent(activeId: number) {
    return http.request<Paginated<Content>>({
        method: "get",
        url: `/api/v1/user/trajectory_active/${activeId}/content`,
    });
}

function getActiveDetails(activeId: number) {
    return http.request<TrajectoryActiveType>({
        method: "get",
        url: `/api/v1/trajectory_active/${activeId}`,
    });
}

export function MaterialSessionPopup() {
    const activeId = useSessionPopup((state) => state.activeId);
    const threatId = useSessionPopup((state) => state.threatId);
    const materialId = useSessionPopup((state) => state.materialId);
    const setMaterialId = useSessionPopup((state) => state.setMaterialId);
    const closePopup = useSessionPopup((state) => state.close);
    const [openPopupTest, setOpenPopupTest] = useState(false);

    const { data: active, status: activeStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--active--details", activeId],
        queryFn: () => getActiveDetails(activeId),
        enabled: activeId !== null,
    });

    const { data: activeData, status: activeDataStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--active--materials", activeId],
        queryFn: () => getActiveContent(activeId),
        enabled: activeId !== null,
    });

    const { data: threat, status: threatStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--threat--details", threatId],
        queryFn: () => getThreatDetails(threatId),
        enabled: threatId !== null,
    });

    const { data: threatData, status: threatDataStatus } = useQuery({
        queryKey: ["MaterialSessionPopup--threat--materials", threatId],
        queryFn: () => getThreatContent(threatId),
        enabled: threatId !== null,
    });

    const [selectedMaterial, setSelectedMaterial] = useState<Content>();

    const isError = activeStatus === "error" || activeDataStatus === "error";
    const isError2 = threatStatus === "error" || threatDataStatus === "error";
    const isPending =
        activeStatus === "pending" || activeDataStatus === "pending";
    const isPending2 =
        threatStatus === "pending" || threatDataStatus === "pending";

    const materials = activeData?.results || threatData?.results || [];
    const material = materials.find((m) => m.id === materialId);

    const materialToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    // console.log({materialToCompletion})

    for (const [i, material] of materials.entries()) {
        if (i === 0) {
            materialToCompletion[material.id] = {
                isAvailable: true,
                prev: null,
            };
        } else {
            const prevMat = materials[i - 1];
            const nextElem = { isAvailable: false, prev: prevMat.id };
            const prevAvailable = materialToCompletion[prevMat.id].isAvailable;

            if (prevAvailable && prevMat.session.percent_progress >= 100) {
                nextElem.isAvailable = true;
            }

            materialToCompletion[material.id] = nextElem;
        }
    }

    const [openList, setOpenList] = useState(false);
    const nextElements = materials.filter(
        (elem) => elem.id !== materialId && elem.session.percent_progress < 100
    );

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isPending === false && threat === undefined) {
            setIsLoading(false);
        }
        if (isPending2 === false && active === undefined) {
            setIsLoading(false);
        }
        if (materialId !== null) {
            setSelectedMaterial(materials.find((m) => m.id === materialId));
        } else {
            setSelectedMaterial(materials[0]);
        }
    }, [isPending, isPending2, active, threat]);
    // console.log(nextElements);
    // console.log(materials);
    // TODO вынести в компонент

    let component = () => {
        if (isError2 || isError) {
            return <div>Произошла ошибка при загрузке материала</div>;
        }
        if (isLoading === true) {
            return <Loader text="Загрузка" />;
        }
        return (
            <div className="relative flex h-full flex-col ">
                <div
                    className={`border-b border-elements-edmaterial p-5 text-tr-m font-medium text-main-dark ${
                        openList === true ? " pb-[110px]" : " pb-0"
                    } `}
                >
                    {active !== undefined ? (
                        <span>{active.active.name}</span>
                    ) : (
                        <>
                            <span>{threat.trajectory_active.active.name}</span>
                            &#129042;
                        </>
                    )}
                    {threat !== undefined ? (
                        <span>{threat.threat.name} </span>
                    ) : (
                        <></>
                    )}
                    {materials.length > 1 ? (
                        <>
                            {openList === false ? (
                                <div
                                    onClick={() => setOpenList(true)}
                                    className="my-5 flex h-[70px] w-full max-w-[1040px] cursor-pointer items-center justify-between rounded-md p-5 shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
                                >
                                    <div className="mt-[5px] flex items-end gap-x-[15px]">
                                        <div className="ml-[3px] mt-[10px] flex">
                                            <SelectedMaterial />
                                        </div>
                                        <div className="mb-[2px] flex items-end gap-x-3">
                                            <p className="w-[40px] text-tr-m font-medium text-orange">
                                                {
                                                    selectedMaterial?.session
                                                        .percent_progress
                                                }
                                                %
                                            </p>
                                            <div className="ml-2 mt-[1px] flex-col gap-y-[10px]">
                                                <p className="text-tr-xxs font-normal text-main-dark">
                                                    {
                                                        selectedMaterial
                                                            ?.content_type
                                                            .custom_name
                                                    }
                                                </p>
                                                <p className="text-tr-s font-medium text-main-dark">
                                                    {selectedMaterial?.name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <ListOpen />
                                </div>
                            ) : (
                                <div
                                    onMouseLeave={() => setOpenList(false)}
                                    className="absolute top-[60px] flex h-full max-h-[500px] w-full max-w-[1040px] cursor-pointer flex-col gap-y-[10px] overflow-y-auto rounded-md bg-white p-5 shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
                                >
                                    {materials.map((m) => (
                                        <ListElem
                                            m={m}
                                            materialId={materialId}
                                            setMaterialId={setMaterialId}
                                            setOpenList={setOpenList}
                                            setSelectedMaterial={
                                                setSelectedMaterial
                                            }
                                            available={
                                                materialToCompletion[m.id]
                                                    .isAvailable
                                            }
                                            threatOrdered={threat.is_ordered}
                                        />
                                    ))}
                                    <div className="pl-[7px]">Финиш</div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="my-[40px] flex items-end gap-x-3">
                            <p className="w-[40px] text-tr-m font-medium text-orange">
                                {selectedMaterial?.session.percent_progress}%
                            </p>
                            <div className="ml-2 mt-[1px] flex-col gap-y-[10px]">
                                <p className="text-tr-xxs font-normal text-main-dark">
                                    {selectedMaterial?.content_type.custom_name}
                                </p>
                                <p className="text-tr-s font-medium text-main-dark">
                                    {selectedMaterial?.name}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="h-full overflow-y-auto p-5">
                    {selectedMaterial != null ? (
                        <ContentAdapter
                            session={selectedMaterial.id}
                            material={selectedMaterial}
                            mutate={() => {}}
                            openPopupTest={false}
                            setOpenPopupTest={setOpenPopupTest}
                        />
                    ) : (
                        <div>Материал еще не выбран</div>
                    )}
                </div>
                {threat?.is_ordered === false ? (
                    <>
                        {nextElements.length !== 0 && (
                            <div
                                className="flex w-full cursor-pointer justify-end pb-[20px] pr-[20px]"
                                onClick={() =>
                                    setSelectedMaterial(nextElements[0])
                                }
                            >
                                <div
                                    className="flex h-[40px] w-[235px] items-center justify-center rounded-full text-tr-s
                                    font-medium text-main-dark shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
                                >
                                    Следующий материал →
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {material?.session?.percent_progress >= 100 && (
                            <div
                                className="flex w-full cursor-pointer justify-end pb-[20px] pr-[20px]"
                                onClick={() =>
                                    setSelectedMaterial(nextElements[0])
                                }
                            >
                                <div
                                    className="flex h-[40px] w-[235px] items-center justify-center rounded-full text-tr-s
                                    font-medium text-main-dark shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
                                >
                                    Следующий материал →
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    return (
        <section
            onClick={closePopup}
            className="fixed inset-0 z-50 bg-main-blue/30 py-[35px]"
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className="relative mx-auto flex  h-full max-h-[900px] min-h-80 w-full max-w-[1805px] flex-col rounded-xl bg-monochrome-white shadow-lg"
            >
                <button
                    className="absolute right-4 top-4 z-50 cursor-pointer"
                    onClick={closePopup}
                >
                    <Cross className="fill-main-dark stroke-main-dark hover:opacity-70" />
                </button>
                {component()}
            </div>
            {!openPopupTest ? (
                ""
            ) : (
                <PopupTest
                    session={selectedMaterial.id}
                    quiz={selectedMaterial.quiz}
                    setOpenPopupTest={setOpenPopupTest}
                />
            )}
        </section>
    );
}
