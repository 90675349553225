import { useEffect, useState } from "react";
import { Tag } from "shared/types";
import * as http from "shared/http";
import { useForm } from "react-hook-form";
import useUser from "shared/api/useUser";
import toast from "react-hot-toast";
import { commitProgress } from "utils/commitProgress";

type Answer = {
    id: number;
    is_correct: boolean;
    content: string;
};

type Question = {
    id: number;
    content: string;
    answers: Answer[];
};

type Quiz = {
    id: number;
    name: string;
    material: number;
    description: string;
    time_create: string;
    questions: Question[];
    tags: Tag[];
};

export const TestMaterial = ({
    quiz,
    session,
}: {
    session: number | string;
    quiz: number;
}) => {
    const { user } = useUser();
    const [quizData, setQuizData] = useState<Quiz>();
    const [isLoading, setIsLoading] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [points, setPoints] = useState(0);
    const [correct, setCorrect] = useState(false);
    const [visible, setVisible] = useState(false);
    const [next, setNext] = useState(false);

    useEffect(() => {
        if (quiz === null) {
            setIsLoading(false);
            return;
        }

        http.request<Quiz>({
            method: "get",
            url: `/api/v1/quiz/material-quiz/${quiz}/`,
        }).then((data) => {
            setQuizData(data);
            setIsLoading(false);
        });
    }, [quiz]);

    const { reset, watch, register, handleSubmit } = useForm<{
        selectedAnswers: boolean[];
    }>({ defaultValues: { selectedAnswers: [] } });

    const watchSelected = watch("selectedAnswers", []);

    if (isLoading || !quizData) {
        return (
            <section className="flex h-full w-full flex-col rounded-xl bg-white">
                <div className="flex h-full flex-col items-center justify-center bg-white py-[20px]">
                    <p className="">
                        Тест в процессе модерации и будет доступен спустя
                        некоторое время
                    </p>
                </div>
            </section>
        );
    }

    const maxQuestion = quizData.questions.length;

    if (currentQuestion >= maxQuestion) {
        return (
            <section className="flex w-full flex-col rounded-xl bg-white">
                <div className="flex h-full flex-col justify-between py-[20px]">
                    Тестирование пройдено на {(points / maxQuestion) * 100.0}%
                </div>
            </section>
        );
    }

    const questionData = quizData.questions[currentQuestion];
    const answersData = questionData.answers;
    const correctCount = answersData.filter((ans) => ans.is_correct).length;
    const selectedCount = watchSelected.filter((checkbox) => checkbox).length;

    function goNext() {
        setVisible(false);
        setNext(false);
        setCurrentQuestion((q) => q + 1);
        reset();
    }

    const onSubmit = (data: { selectedAnswers: boolean[] }) => {
        setNext(true);

        let isOk = true;
        for (const [id, checkbox] of data.selectedAnswers.entries()) {
            if (answersData[id].is_correct !== checkbox) {
                isOk = false;
                setVisible(true);
                setCorrect(false);
                break;
            }
        }

        if (isOk) {
            setPoints((p) => p + 1);
            setVisible(true);
            setCorrect(true);
        }

        // TODO
        // unregister("selectedAnswers");

        if (currentQuestion + 1 === maxQuestion) {
            commitProgress(session, 100);
            http.request({
                method: "post",
                url: `/api/v1/quiz/results`,
                data: {
                    score: (points + Number(isOk)) / maxQuestion,
                    quiz: quiz,
                    user: user.id,
                },
            }).then(() => {
                toast.success("Результат сохранен");
            });
        }
    };

    return (
        <section className="flex w-full flex-col rounded-xl bg-white">
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex h-full flex-col justify-between py-[20px]"
            >
                <div className="flex flex-col gap-[20px]">
                    <div className="flex flex-col gap-[10px]">
                        <p className="text-[16px] font-medium leading-[19.2px] text-[#F37D73]">
                            {`Вопрос ${currentQuestion + 1} из ${maxQuestion}`}
                        </p>
                        <p className="text-[14px] font-normal leading-[16.8px] text-[#264354]">
                            Выберите {correctCount} правильных ответов.
                        </p>
                        <div className="flex flex-col gap-[20px]">
                            <p className="text-[16px] font-medium leading-[19.2px] text-[#264354]">
                                {questionData.content}
                            </p>
                            <div className="flex flex-col gap-[20px]">
                                {questionData.answers.map((answer, index) => {
                                    // const { onChange, onBlur, name, ref } = register(`selectedAnswers.${index}`);
                                    return (
                                        <div className="flex items-center gap-[20px]">
                                            <input
                                                type="checkbox"
                                                id={`question-${currentQuestion}-answer-${index}-checkbox`}
                                                className="hidden-checkbox"
                                                {...register(
                                                    `selectedAnswers.${index}`,
                                                    {
                                                        value: false,
                                                        shouldUnregister: true,
                                                    }
                                                )}
                                            />
                                            <label
                                                htmlFor={`question-${currentQuestion}-answer-${index}-checkbox`}
                                                className={`custom-checkbox-white`}
                                            ></label>
                                            <p className="text-[14px] font-normal leading-[16.8px] text-[#264354]">
                                                {answer.content}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {visible === true && (
                        <>
                            {correct === true ? (
                                <p className="mt-[30px] text-[16px] font-normal leading-[19.2px] text-[#C53225]">
                                    Верно!
                                </p>
                            ) : (
                                <p className="mt-[30px] text-[16px] font-normal leading-[19.2px] text-[#C53225]">
                                    Неверно!
                                </p>
                            )}
                        </>
                    )}
                </div>
                {next === true ? (
                    <div className="flex justify-end">
                        {/* <ButtonBack handleClick={handleClickAnswer} /> */}
                        {/* <ButtonAnswer handleClick={handleClickAnswer} /> */}
                        <div
                            className={`w-full max-w-[160px] cursor-pointer ${
                                selectedCount === 0 && "opacity-60"
                            }`}
                            onClick={goNext}
                        >
                            <div className="flex h-[45px] items-center justify-center rounded-full bg-blue">
                                <p className="text-[14px] font-medium leading-[16.8px] text-white">
                                    Далее
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-end">
                        {/* <ButtonBack handleClick={handleClickAnswer} /> */}
                        {/* <ButtonAnswer handleClick={handleClickAnswer} /> */}
                        <button
                            disabled={selectedCount === 0}
                            className={`w-full max-w-[160px] ${
                                selectedCount === 0 && "opacity-60"
                            }`}
                        >
                            <div className="flex h-[45px] items-center justify-center rounded-full bg-blue">
                                <p className="text-[14px] font-medium leading-[16.8px] text-white">
                                    Ответ
                                </p>
                            </div>
                        </button>
                    </div>
                )}
            </form>
        </section>
    );
};
